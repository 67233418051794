import {GET_SELECTED_FAMILY_MEMBER} from '../../actiontypes';

const initial_state = {
    selectedFamilyMember:0
};
const SelectedFamilyMemberReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_SELECTED_FAMILY_MEMBER:
            return { ...state,  selectedFamilyMember: action.payload }

        default:
            return state;
    }
}
export default SelectedFamilyMemberReducer;