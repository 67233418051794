import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../components/async.component';
import Toolbar from '../layouts/website-layout';
import NoLayout from '../layouts/layout-none.component';


// USER LOG IN
// USER FORGOT PASSWORD
const Login = asyncComponent(() => import('../containers/authentication/login/login.component'));
const ForgotPassword = asyncComponent(() => import('../containers/authentication/forgot-password/forgot-password.component'));

// USER PROFILE
const EditProfile = asyncComponent(() => import('../containers/authentication/profile/EditProfile'));
const userSetting = asyncComponent(() => import('../containers/authentication/profile/Setting'))

// DASHBOARD ROUTE
const Dashboard = asyncComponent(() => import('../containers/listing/Dashboard'));
const SocialFeed = asyncComponent(() => import('../containers/pages/social/feed'));

// MANAGE LISTINGS
const EditListing = asyncComponent(() => import('../containers/listing/EditListing'));
const Connections = asyncComponent(() => import('../containers/listing/Tabs/ConnectionsTab'));
const Messages = asyncComponent(() => import('../containers/listing/Tabs/MessagesTab'));

// CREATE LISTINGS
const CreateListing =   asyncComponent(() => import('../containers/listing/CreateListing'));

// CONTENT PAGES
const Home = asyncComponent(() => import('../containers/pages/Home'));
const About = asyncComponent(() => import('../containers/pages/About'));
const Content = asyncComponent(()=>import('../containers/pages/Content'));

// ERROR ROUTES
const NotFound = asyncComponent(() => import('../containers/pages/error404/not-found.component'));

// USER ACCOUNT COMPONENTS
const CreateAccountUserInfo = asyncComponent(() => import('../containers/account/CreateAccount-UserInfo'));
const SelectAccountType = asyncComponent(() => import('../containers/account/SelectAccountType'));
const UserEmailVerification = asyncComponent(() => import('../containers/account/UserEmailVerification'));


// SEARCHING
const searchResults = asyncComponent(() => import('../containers/searching/searchResults.js'));
const DisplayListing = asyncComponent(() => import('../containers/searching/displayListing'));

const FeaturedListing = asyncComponent(() => import('../containers/featured/index'));

// SPONSOR LISTING
const SponsorCommunityStep1 = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-request'));
const SponsorCommunityStep1Accept = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-accept'));
const SponsorCommunityStep2 = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-manage-payment-info'));
const SponsorCommunityStep2Accept = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-accept-payment-info'));
const SponsorCommunityStep2Add = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-add-payment-info'));
const SponsorCommunityStep3 = asyncComponent(()=>import('../containers/searching/sponsor/sponsor-community-finalize-payment'));
const SponsorTermsOfService = asyncComponent(()=>import('../containers/pages/SponsorshipTerms'));

// PAYMENT METHODS
const PaymentMethods = asyncComponent(()=>import('../containers/account/PaymentMethods'));


const UserDashboard = asyncComponent(() => import('../containers/user/Dashboard'));
const UserFavorites = asyncComponent(() => import('../containers/user/Favorites'));
const UserSavedIncentives = asyncComponent(() => import('../containers/user/SavedIncentives'));
const UserContacts = asyncComponent(() => import('../containers/user/Contacts'));

const InviteToTeam = asyncComponent(() => import('../containers/invite/InviteToTeam'));
const InviteUserCreate = asyncComponent(() => import('../containers/invite/UserCreate'));

//ANGEL MA'AM PAGES END
const AllUserProfile = asyncComponent(() => import('../components/profile'));
const userProfilePage = asyncComponent(()=>import('../containers/searching/userProfilePage'));

// EVENTS PAGES
const ViewEvent = asyncComponent( () => import("../containers/events/index"));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props => (<Layout>
          <Component {...props} />
        </Layout>)}
    />
);

const ToolbarLayout = props => (
  <Toolbar>{props.children}</Toolbar>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout }) => {
  let activeLayout;
  switch (layout.currentLayout) {
    case 'classic':
      activeLayout = ToolbarLayout;
      break;

    case 'HOME':
      activeLayout = ToolbarLayout;
      break;

    case 'toolbar':
      activeLayout = ToolbarLayout;
      break;

    default:
      activeLayout = ToolbarLayout;
  }

  return (
  <Switch>
    <AppRoute path="/" exact component={Home} props={childProps} layout={activeLayout} />
    <AppRoute path="/about" exact component={About} props={childProps} layout={activeLayout} />
    <AppRoute path="/social-feed" exact component={SocialFeed} props={childProps} layout={activeLayout} />

    <AppRoute path="/contact-us" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/coupons" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/travel" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/privacy" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/news" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/terms-of-service" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/help-center" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/support-center" exact component={Content} props={childProps} layout={activeLayout} />
    <AppRoute path="/featured-premium-terms-of-service" exact component={Content} props={childProps} layout={activeLayout} />

    <AppRoute path="/payment-methods" exact component={PaymentMethods} props={childProps} layout={activeLayout} />

    <AppRoute path="/community/:id/:name" exact component={DisplayListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/provider/:id/:name" exact component={DisplayListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/volunteer/:id/:name" exact component={DisplayListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/profile/:id" exact component={userProfilePage} props={childProps} layout={activeLayout} />
    <AppRoute path="/profile/:id/:name" exact component={userProfilePage} props={childProps} layout={activeLayout} />
    <AppRoute path="/search" exact component={searchResults} props={childProps} layout={activeLayout} />

    <AppRoute path="/login" exact component={Login} props={childProps} layout={NoLayout} />
    <AppRoute path="/forgot-password" exact component={ForgotPassword} props={childProps} layout={NoLayout} />
    <AppRoute path="/create-account" exact component={SelectAccountType} props={childProps} layout={activeLayout} />
    <AppRoute path="/select-option" exact component={SelectAccountType} props={childProps} layout={activeLayout} />
    <AppRoute path="/registration" exact component={CreateAccountUserInfo} props={childProps} layout={activeLayout} />
    <AppRoute path="/create-option" exact component={CreateListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/users/email-verification" exact component={UserEmailVerification} props={childProps} layout={activeLayout} />

    <AppRoute path="/sponsor/terms-details" exact component={SponsorTermsOfService} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/terms" exact component={SponsorCommunityStep1} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/accept-terms" exact component={SponsorCommunityStep1Accept} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/payment-info" exact component={SponsorCommunityStep2} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/accept-payment-info" exact component={SponsorCommunityStep2Accept} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/add-payment-info" exact component={SponsorCommunityStep2Add} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/finalize-payment" exact component={SponsorCommunityStep3} props={childProps} layout={activeLayout} />
    <AppRoute path="/sponsor/finalize-payment/:communityId" exact component={SponsorCommunityStep3} props={childProps} layout={activeLayout} />

    <AppRoute path="/dashboard" exact component={Dashboard} props={childProps} layout={activeLayout} />
    <AppRoute path="/featured-listing" exact component={FeaturedListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/create-listing" exact component={CreateListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/listing" exact component={EditListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/listing/:tab" exact component={EditListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/listing/:tab/:subtab" exact component={EditListing} props={childProps} layout={activeLayout} />
    <AppRoute path="/edit-profile" exact component={EditProfile} props={childProps} layout={activeLayout} />
    <AppRoute path="/setting" exact component={userSetting} props={childProps} layout={activeLayout} />
    <AppRoute path="/setting/:tab" exact component={userSetting} props={childProps} layout={activeLayout} />

    {/* USER ROUTES */}
    <AppRoute path="/user-dashboard" exact component={UserDashboard} props={childProps} layout={activeLayout} />
    <AppRoute path="/user-profile/favorites" exact component={UserFavorites} props={childProps} layout={activeLayout} />
    <AppRoute path="/user-profile/saved-incentives" exact component={UserSavedIncentives} props={childProps} layout={activeLayout} />
    <AppRoute path="/user-profile/contacts" exact component={UserContacts} props={childProps} layout={activeLayout} />

    <AppRoute path="/event/:id" exact component={ViewEvent} props={childProps} layout={activeLayout} />
    <AppRoute path="/event/:id/:name" exact component={ViewEvent} props={childProps} layout={activeLayout} />

    <AppRoute path="/community-search" exact component={searchResults} props={childProps} layout={activeLayout} />
    <AppRoute path="/community-connections" exact component={Connections} props={childProps} layout={activeLayout} />

    {/* ankita */}
    <AppRoute path="/detail-profile" exact component={AllUserProfile} props={childProps} layout={activeLayout} />

    {/* End of Path Community After Login */}
    <AppRoute path="/provider-details" exact component={EditProfile} props={childProps} layout={activeLayout} />
    <AppRoute path="/messages" exact component={Messages} props={childProps} layout={activeLayout} />

    <AppRoute path="/invite/:userId/:teamId" exact component={InviteToTeam} props={childProps} layout={activeLayout} />
    <AppRoute path="/invite/:userId/:teamId/:listingId" exact component={InviteToTeam} props={childProps} layout={activeLayout} />
    <AppRoute path="/invite/user-create" exact component={InviteUserCreate} props={childProps} layout={activeLayout} />

    <AppRoute component={NotFound} layout={activeLayout} />
  </Switch>);
};
