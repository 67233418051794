import React from 'react';
import themes from '../../themes';

export const configuredTheme = themes[0].theme;
export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

export const CommunitymenuItems = [
    {
        title: 'DASHBOARD',
        href: '/dashboard',
        icon: <i className="fas fa-home-lg-alt" />,
    },
    {
        title: 'FEED',
        href: '/social-feed',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'JOB BOARD',
        href: 'https://jobs.seniorplicity.com',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'COUPONS',
        href: '/coupons',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'TRAVEL',
        href: '/travel',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'SEARCH',
        href: '/search',
        icon: <i className="far fa-search" />,
    }
];
