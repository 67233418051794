import React from 'react';
import themes from './themes';

export const ApiURL = (process.env.REACT_APP_SERVER_ENVIRONMENT == 'staging')?process.env.REACT_APP_API_URL+'/websiteApi':"https://cp.seniorplicity.com/websiteApi";
export const GoogleMapAPI = "AIzaSyD0nk6eLDoZeJGt53miI-ZOliBkLZav5Ls";

export const languageText = 'en';

export const configuredTheme = themes[0].theme;
export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

export const menuItems = [
    {
        title: 'HOME',
        href: '/',
        icon: <i className="far fa-home" />,
    },
    {
        title: 'SEARCH',
        href: '/search',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'JOB BOARD',
        href: 'https://jobs.seniorplicity.com',
        icon: <i className="far fa-search" />,
    },
    {
        title: 'ABOUT',
        href: '/about',
        icon: <i className="far fa-question-circle" />,
    },
    {
        title: 'LOGIN',
        href: '/login',
        icon: <i className="far fa-sign-in-alt" />,
    },
    {
        title: 'CREATE ACCOUNT',
        href: '/create-account',
        icon: <i className="far fa-user-plus" />,
    },
    {
        title: 'CONTACT US',
        href: '/contact-us',
        icon: <i className="far fa-envelope" />,
    }
];

export const stateList = [
    {"name":"Alabama","short":"AL"},
    {"name":"Alaska","short":"AK"},
    {"name":"Arizona","short":"AZ"},
    {"name":"Arkansas","short":"AR"},
    {"name":"California","short":"CA"},
    {"name":"Colorado","short":"CO"},
    {"name":"Connecticut","short":"CT"},
    {"name":"Delaware","short":"DE"},
    {"name":"District of Columbia","short":"DC"},
    {"name":"Florida","short":"FL"},
    {"name":"Georgia","short":"GA"},
    {"name":"Hawaii","short":"HI"},
    {"name":"Idaho","short":"ID"},
    {"name":"Illinois","short":"IL"},
    {"name":"Indiana","short":"IN"},
    {"name":"Iowa","short":"IA"},
    {"name":"Kansas","short":"KS"},
    {"name":"Kentucky","short":"KY"},
    {"name":"Lousiana","short":"LA"},
    {"name":"Maine","short":"ME"},
    {"name":"Maryland","short":"MD"},
    {"name":"Massachusetts","short":"MA"},
    {"name":"Michigan","short":"MI"},
    {"name":"Minnesota","short":"MN"},
    {"name":"Mississippi","short":"MS"},
    {"name":"Missouri","short":"MO"},
    {"name":"Montana","short":"MT"},
    {"name":"Nebraska","short":"NE"},
    {"name":"Nevada","short":"NV"},
    {"name":"New Hampshire","short":"NH"},
    {"name":"New Jersey","short":"NJ"},
    {"name":"New Mexico","short":"NM"},
    {"name":"New York","short":"NY"},
    {"name":"North Carolina","short":"NC"},
    {"name":"North Dakota","short":"ND"},
    {"name":"Ohio","short":"OH"},
    {"name":"Oklahoma","short":"OK"},
    {"name":"Oregon","short":"OR"},
    {"name":"Pennsylvania","short":"PA"},
    {"name":"Rhode Island","short":"RI"},
    {"name":"South Carolina","short":"SC"},
    {"name":"South Dakota","short":"SD"},
    {"name":"Tennessee","short":"TN"},
    {"name":"Texas","short":"TX"},
    {"name":"Utah","short":"UT"},
    {"name":"Vermont","short":"VT"},
    {"name":"Virginia","short":"VA"},
    {"name":"Washington","short":"WA"},
    {"name":"West Virginia","short":"WV"},
    {"name":"Wisconsin","short":"WI"},
    {"name":"Wyoming","short":"WY"}
];
