export const GET_LOGIN_REQUEST='get_login_request';
export const GET_LOGIN_SUCCESS='get_login_success';
export const GET_LOGIN_ERROR='get_login_error';
export const GET_COMMUNITY_TYPE='get_community_type';
export const GET_COMMUNITY_TYPE_FAIL='get_community_type_fail';
export const GET_SEARCH_TYPE='get_search_type';
export const GET_SEARCH_TYPE_FAIL='get_search_type_fail';
export const GET_UNREAD='get_unread';
export const GET_UNREAD_FAIL='get_unread_fail';
export const GET_UNREAD_CONNECTION= 'get_unread_connection';
export const GET_ADDED_SPONSORS='get_added_sponsors';
export const GET_SPONSOR_COUNT='get_sponsor_count';
export const GET_NOTIFICATION='GET_NOTIFICATION';
export const SET_NOTIFICATION='SET_NOTIFICATION';
export const GET_UNREAD_ALERTS='GET_UNREAD_ALERTS';
export const GET_FAMILY_MEMBERS='GET_FAMILY_MEMBERS';
export const GET_SELECTED_FAMILY_MEMBER='GET_SELECTED_FAMILY_MEMBER';
export const GET_SPONSER_LISTING_COUNT='GET_SPONSER_LISTING_COUNT';