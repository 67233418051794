import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                            src='/assets/images/loader2.gif'
                            alt="loader"
                            title="loader"
                            width="60"
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}
export default Loader;
