import {
    GET_DATA,
    GET_LISTING_AMOUNT,
    SHOW_SEARCH_BTN
} from './types';
import axios from 'axios';

import { ApiURL } from "../../config";
import {returnErrors} from "./messages";
import {
    GET_ADDED_SPONSORS, GET_FAMILY_MEMBERS, GET_SELECTED_FAMILY_MEMBER,
    GET_SPONSOR_COUNT,
    GET_UNREAD,
    GET_UNREAD_ALERTS,
    GET_UNREAD_CONNECTION,
    GET_SPONSER_LISTING_COUNT
} from "../../actiontypes";
import {CHANGE_SIDENAV_TOOLBAR_BACKGROUND} from "./theme.actions";

export const updateListingImageOrder = (communityId, imageList) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/comm_image/updateListingImageOrder`, {communityId, imageList}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const updateListingMainImage = (communityId, imageId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/comm_image/updateListingMainImage`, {communityId, imageId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const sponsorListingRequest = (communityId, receiverId, receiverName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/sponsorListingRequest`, {communityId, receiverId, receiverName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getSponsorListingRequests = (myCommunityId, sponsorId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getSponsorListingRequests`, {myCommunityId, sponsorId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getSponsorListingCount = (myCommunityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getSponsorListingCount`, {myCommunityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_SPONSER_LISTING_COUNT,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getCommunityLinkInfo = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getCommunityLinkInfo`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getPaymentProfile = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getPaymentProfile`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getAuthNetPaymentProfile = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getAuthNetPaymentProfile`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getSponsorList = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getSponsorList`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteCommunities = (communityList) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/deleteCommunities`, {communityList}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const setAutoRenewSponsorship = (sponsorId, autoRenew) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/setAutoRenewSponsorship`, {sponsorId, autoRenew}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const cancelSponsorshipRequest = (communityId, cancelId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/cancelSponsorshipRequest`, {communityId, cancelId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const cancelPaidSponsorshipRequest = (communityId, cancelId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/cancelPaidSponsorshipRequest`, {communityId, cancelId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const confirmSponsorshipRequest = (communityId, confirmId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/confirmSponsorshipRequest`, {communityId, confirmId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("confirmSponsorshipRequest Error: ", err);
    }
};
export const completeListingSponsorship = (communityId, sponsorId, paymentTerm, autoRenew, subscriptionPaymentId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/completeListingSponsorship`, {communityId, sponsorId, paymentTerm, autoRenew, subscriptionPaymentId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("completeListingSponsorship Error: ", err);
    }
};
export const getSponsorshipInfo = (receiveId, requestId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getSponsorshipInfo`, {receiveId, requestId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("getSponsorshipInfo Error: ", err);
    }
};
export const inviteToSponsor = (receiveId, requestId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/inviteToSponsor`, {receiveId, requestId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("inviteToSponsor Error: ", err);
    }
};
export const acceptSponsorship = (receiver_id, community_provider_id) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/acceptSponsorship`, {receiver_id, community_provider_id}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("acceptSponsorship Error: ", err);
    }
};
export const pauseSponsorship = (communityId, receiverId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/pauseSponsorship`, {communityId, receiverId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("pauseSponsorship Error: ", err);
    }
};
export const unPauseSponsorship = (communityId, receiverId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/unPauseSponsorship`, {communityId, receiverId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};

export const getNotifications = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getNotifications`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const getNotificationsBadges = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getNotificationBadges`, {communityId}, tokenConfig(getState) );

        dispatch ({
            type: GET_UNREAD,
            payload: res.data.newMessages
        });

        dispatch ({
            type: GET_UNREAD_ALERTS,
            payload: res.data.newNotifications
        });

        dispatch ({
            type: GET_UNREAD_CONNECTION,
            payload: res.data.newConnections
        });

        dispatch ({
            type: GET_SPONSOR_COUNT,
            payload: res.data.newSponsors
        });

    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const markNotificationRead = (notificationId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/markNotificationRead`, {notificationId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const deleteNotification = (notificationId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/deleteNotification`, {notificationId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};




/*
#####################################################################
USER FUNCTIONS
#####################################################################
*/
export const getUserDashboard = (familyMemberId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getUserDashboard`, {familyMemberId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });

        if (res.data.dashboardMember) {
            dispatch ({
                type: GET_SELECTED_FAMILY_MEMBER,
                payload: res.data.dashboardMember
            });
        }
        if (res.data.familyMembers) {
            dispatch ({
                type: GET_FAMILY_MEMBERS,
                payload: res.data.familyMembers
            });
        }
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const getUserDashboardFilter = (familyMemberId, incentiveMenu, eventMenu) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getUserDashboardFilter`, {familyMemberId, incentiveMenu, eventMenu}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const getUserFavorites = (familyMemberId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getUserFavorites`, {familyMemberId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });

        if (res.data.familyMembers) {
            dispatch ({
                type: GET_FAMILY_MEMBERS,
                payload: res.data.familyMembers
            });
        }
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const saveAddFamilyMember = (familyName, familyRelationship, familyId, searchZip) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/saveAddFamilyMember`, {familyName, familyRelationship, familyId, searchZip}, tokenConfig(getState) );
        dispatch ({type: GET_DATA, payload: res.data});
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const saveFamilyFavorites = (familyFavorites, listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/saveFamilyFavorites`, {familyFavorites, listingId}, tokenConfig(getState) );
        dispatch ({type: GET_DATA, payload: res.data});
        if (res.data.familyMembers) {
            dispatch ({
                type: GET_FAMILY_MEMBERS,
                payload: res.data.familyMembers
            });
        }
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};
export const setUserUnFavorite = (familyId, listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/setUserUnFavorite`, {familyId, listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveFamilyHomeCommunity = (familyHomeCommunity, listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/saveFamilyHomeCommunity`, {familyHomeCommunity, listingId}, tokenConfig(getState) );
        dispatch ({type: GET_DATA, payload: res.data});
        if (res.data.familyMembers) {
            dispatch ({
                type: GET_FAMILY_MEMBERS,
                payload: res.data.familyMembers
            });
        }
    }
    catch (err) {
        console.log("unPauseSponsorship Error: ", err);
    }
};

export const getFamilyContacts = (familyMemberId, contactId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getFamilyContacts`, {familyMemberId, contactId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const addToFamilyContacts = (familyMemberId, contactId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/addToFamilyContacts`, {familyMemberId, contactId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getUserContacts = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getUserContacts`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteUserContact = (contactId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/deleteUserContact`, {contactId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteFamilyContact = (contactId, familyMemberId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/deleteFamilyContact`, {contactId, familyMemberId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteHomeCommunity = (familyId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/deleteHomeCommunity`, {familyId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
        if (res.data.familyMembers) {
            dispatch ({
                type: GET_FAMILY_MEMBERS,
                payload: res.data.familyMembers
            });
        }
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const updateSelectedFamilyMember = memberId => ({
    type: GET_SELECTED_FAMILY_MEMBER,
    payload: memberId
});

// SOCIAL FEED
export const loadSocialFeed = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/social/feed`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// LOAD LISTING INCENTIVES
export const getListingIncentives = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/getListingIncentives`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getShareListingEmailTemplate = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getShareListingEmailTemplate`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getEmailTemplate = (templateName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/getEmailTemplate`, {templateName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getUserPermissions = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/getUserPermissions`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getFeaturedStatus = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/getFeaturedStatus`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getListingSubscriptionAmount = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/config/getListingAmount` , tokenConfig(getState) );
        dispatch ({
            type: GET_LISTING_AMOUNT,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getUserPaymentProfile = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/getUserPaymentProfile`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const updateCard = (cardInfo, billingInfo) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/updateCard`, {cardInfo, billingInfo}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// LISTING SEARCH

export const listingSearch = (searchType, searchFields) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/search`, {searchType, searchFields}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const recordStats = (statsType, statsId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/recordStats`, {statsType, statsId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// LISTING PAGE
export const loadListingData = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/loadListingData`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const connectToListing = (listingId, myId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/createConnection`, {listingId, myId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const disconnectFromListing = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/deleteConnection`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getConnectionStatus = (listingId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/getConnectionStatus`, {listingId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const checkFavoriteStatus = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/checkFavoriteStatus`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const favoriteListing = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/createFavorite`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const unFavoriteListing = (listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/deleteFavorite`, {listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const loadMetaData = (filterType) => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/company/meta_data/${filterType}`, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const loadMultiMetaData = (metaData) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/company/multiDataTypes`, {metaData}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// CONNECTIONS
export const getConnections = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/getConnections`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const approveConnectionRequest = (connId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/approveConnectionRequest`, {connId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const declineConnectionRequest = (connId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/declineConnectionRequest`, {connId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteConnection = (connId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/deleteConnection`, {connId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const disconnectConnection = (connId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/disconnectConnection`, {connId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const reconnectConnection = (connId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/reconnectConnection`, {connId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendConnectionInvitation = (communityId, inviteContacts) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/sendConnectionInvitation`, {communityId, inviteContacts}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getInvitedContacts = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/getInvitedContacts`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const rsvpToEvent = (eventId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/event/rsvpToEvent`, {eventId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendContactConnection = (subject, message, connectionId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/sendContactConnections`, {subject, message, connectionId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendContactEventMessage = (subject, message, eventId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/event/sendContactEventMessage`, {subject, message, eventId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendContactAttendeeMessage = (subject, message, userId, eventId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/event/sendContactAttendeeMessage`, {subject, message, userId, eventId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const loadContacts = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/loadContacts`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// LISTING INCENTIVE FUNCTIONS
export const saveIncentive = (incentiveId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/saveIncentive`, {incentiveId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const unSaveIncentive = (incentiveId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/unSaveIncentive`, {incentiveId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareIncentive = (incentiveId, communityId, selectedContacts, recipientEmailAddress, recipientName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/incentive/sendShareIncentive`, {incentiveId, communityId, selectedContacts, recipientEmailAddress, recipientName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const sendShareIncentiveUser = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendShareIncentiveUser`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareBusiness = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendShareBusiness`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareEventUser = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendShareEventUser`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendContactBusiness = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendContactBusiness`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendContactUser = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendContactUser`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareContact = (data) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/emails/sendShareContact`, {data}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};



export const sendShareSavedIncentive = (incentiveId, communityId, selectedContacts, recipientEmailAddress, recipientName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/incentive/sendShareSavedIncentive`, {incentiveId, communityId, selectedContacts, recipientEmailAddress, recipientName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareEvent = (eventId, communityId, selectedContacts, recipientEmailAddress, recipientName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/sendShareEvent`, {eventId, communityId, selectedContacts, recipientEmailAddress, recipientName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareListing = (myCommunityId, communityId, selectedContacts, recipientEmailAddress, recipientName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/listings/sendShareListing`, {myCommunityId, communityId, selectedContacts, recipientEmailAddress, recipientName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendShareConnection = (connectionId, communityId, selectedContacts, recipientEmailAddress, recipientName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/connections/sendShareConnections`, {connectionId, communityId, selectedContacts, recipientEmailAddress, recipientName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// ACCOUNT INCENTIVE FUNCTIONS
export const getSavedIncentives = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/getSavedIncentives`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteSavedIncentive = (incentiveId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/deleteSavedIncentive`, {incentiveId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const pauseIncentive = (incentiveId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/pauseIncentive`, {incentiveId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const deleteIncentive = (incentiveId, communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/deleteIncentive`, {incentiveId, communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const getIncentives = (communityId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/community/getIncentives`, {communityId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// PUBLIC USER PROFILE FUNCTIONS
export const getProfile = (userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/getProfile`, {userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const addToContacts = (contactId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/addToContacts`, {contactId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const removeFromContacts = (contactId, familyMemberId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/removeFromContacts`, {contactId, familyMemberId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};


/*
browser / app notifications
*/
export const updateFirebaseToken = (firebaseTokens) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/notifications/updateFirebaseToken`, {firebaseTokens}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendFirebaseNotification = (firebaseTokens) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/notifications/sendFirebaseNotification`, {firebaseTokens}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};



// #################################################################################
// NEW USER DASHBOARD FUNCTIONS
// #################################################################################
export const getClientDashboard = () => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${ApiURL}/dashboard/getClientDashboard`, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const updateUserListings = (selectedUserId, selectedListings) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/updateUserListings`, {
            selectedUserId,
            userRole: selectedListings["userRole"],
            salesListings: selectedListings["salesListings"],
            regionalListings: selectedListings["regionalListings"],
            salesOriginalListings: selectedListings["salesOriginalListings"],
            regionalOriginalListings: selectedListings["regionalOriginalListings"]
        }, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const removeUserFromTeam = (userInfo) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/removeUserFromTeam`, {userInfo}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const removeUserFromLocation = (userInfo, listingInfo) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/removeUserFromLocation`, {userInfo, listingInfo}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveAssignedListingUser = (listingInfo, newUserId, userRole) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/saveAssignedListingUser`, {listingInfo, newUserId, userRole}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveListingPermissions = (listingInfo, userId, userRole, userPermissions) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/saveListingPermissions`, {listingInfo, userId, userRole, userPermissions}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendInviteUserToTeam = (userRole, emailAddress) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/sendInviteUserToTeam`, {userRole, emailAddress}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendInviteUserToListing = (listingId, userRole, emailAddress) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/sendInviteUserToListing`, {listingId, userRole, emailAddress}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveTeamName = (teamId, teamName) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/saveTeamName`, {teamId, teamName}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const sendResendInviteUser = (inviteUserId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/sendResendInviteUser`, {inviteUserId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
// #################################################################################


// #################################################################################
// INVITE FUNCTIONS
// #################################################################################
export const getInvite = (teamId, userId, listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/invite/getInvite`, {teamId, userId, listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const declineUserInvite = (teamId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/invite/declineUserInvite`, {teamId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const acceptUserInvite = (teamId, userId, listingId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/invite/acceptUserInvite`, {teamId, userId, listingId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
export const saveUserInviteInformation = (password, confirmPassword, firstName, lastName, teamId, userId) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/invite/saveUserInviteInformation`, {password, confirmPassword, firstName, lastName, teamId, userId}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

// #################################################################################



// FORGOT PASSWORD
export const resetPasswordRequest = (emailAddress) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/resetPasswordRequest`, {emailAddress}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
// FORGOT PASSWORD
export const confirmVerificationCode = (emailAddress, verificationCode) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/confirmVerificationCode`, {emailAddress, verificationCode}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
// FORGOT PASSWORD
export const resetPasswordConfirmed = (emailAddress, verificationCode, password) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/user/resetPasswordConfirmed`, {emailAddress, verificationCode, password}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};


export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().LoginReducer.stateLoginValue.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
};
// save family filter
export const saveFamilySearchFilter = (selectedFilter) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/saveFamilySearchFilter`, {selectedFilter}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
// clear family filter
export const clearFamilySearchFilter = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/clearFamilySearchFilter`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};
// get family filter
export const getFamilySearchFilter = () => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/dashboard/getFamilySearchFilter`, {}, tokenConfig(getState) );
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

//
export const setShowSearchBtn = (visibility) => {
    console.log("visibility",visibility);
    return (dispatch) => {
        dispatch({ type: SHOW_SEARCH_BTN, payload: visibility});
    }
};

