import React from 'react';
import PropTypes from 'prop-types';

// Material components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import themeStyles from './content-footer.theme.style';

const ContentFooter = (props) => {
    const { classes, ...other } = props;

    return (
        <AppBar
            color="default"
            position="static"
            {...other}
        >
            <Toolbar>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <small>&copy; 2009-2022 Seniorplicity Inc. All rights reserved</small>
                        </div>
                        <div className="col-6 text-right">
                            <small>
                                <a href="/privacy">Privacy Policy</a> {' '} | {' '}
                                <a href="/terms-of-service">Terms & Conditions</a>
                            </small>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

ContentFooter.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

export default withStyles(themeStyles)(ContentFooter);
