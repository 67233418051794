import {GET_LOGIN_REQUEST , GET_LOGIN_SUCCESS, GET_LOGIN_ERROR} from '../../actiontypes';


const INITIAL_STATE = {
    loadingaIndicatoranswerdetail: false,
    stateLoginValue:[]
}; 

const LoginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LOGIN_REQUEST:
            return { ...state, loadingaIndicatoranswerdetail: true}

        case GET_LOGIN_SUCCESS:
            //console.log("Running Reducer - GET_LOGIN_SUCCESS", action)
            return { ...state, loadingaIndicatoranswerdetail: false, stateLoginValue:action.payload }

        case GET_LOGIN_ERROR:
            return { ...state, loadingaIndicatoranswerdetail: false }    
        default:
            return state;
    }
}
export default LoginReducer;