import React from 'react';
import PropTypes from 'prop-types';

// Material components
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from "react-redux";
import axios from 'axios';
import {sitedata} from '../sitedata'
import {LoginFetchReducer} from '../client/actions/users';
import {UnreadmsgFetchReducer} from '../client/actions/unreadMessages';
import {UnreadConnectionFetchReducer} from '../client/actions/connections';
import {Link, NavLink} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import styles from './mobile-nav-alt.style';

import {menuItems} from '../config';
import {CommunitymenuItems} from '../client/routes/communityConfig';
import {ProvidermenuItems} from '../client/routes/ProviderConfig';
import {VolunteermenuItems} from '../client/routes/volunteerConfig';
import {SubProvidermenuItems} from '../client/routes/SubProviderConfig'
import {EndUserItems} from '../client/routes/EndUserConfig';
import {SponsorCountReducer} from '../client/actions/sponsors';


class WebsiteNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {anchor: null, open: null, menuItems: [], unreadmsg: false};
    }

    componentDidMount() {
        if (!this.props.stateLoginValue.user_type) {
            this.setState({menuItems: menuItems});
        }
        if (this.props.stateLoginValue.user_type === 0) {
            this.setState({menuItems: CommunitymenuItems});
        }
        if (this.props.stateLoginValue.user_type === 1) {
            this.setState({menuItems: ProvidermenuItems});
        }
        if (this.props.stateLoginValue.user_type === 2) {
            this.setState({menuItems: VolunteermenuItems});
        }
        if (this.props.stateLoginValue.user_type === 3) {
            this.setState({menuItems: EndUserItems});
        }
        if (this.props.stateLoginValue.user_type === 4) {
            this.setState({menuItems: SubProvidermenuItems});
        }
        if (this.props.stateLoginValue.user_type === "") {
            this.setState({menuItems: menuItems});
        }
    }

    componentWillReceiveProps(newProps) {
        if (!newProps.stateLoginValue.user_type) {
            this.setState({menuItems: menuItems});
        }
        if (newProps.stateLoginValue.user_type === 0) {
            this.setState({menuItems: CommunitymenuItems});
        }
        if (newProps.stateLoginValue.user_type === 1) {
            this.setState({menuItems: ProvidermenuItems});
        }
        if (newProps.stateLoginValue.user_type === 2) {
            this.setState({menuItems: VolunteermenuItems});
        }
        if (newProps.stateLoginValue.user_type === 3) {
            this.setState({menuItems: EndUserItems});
        }
        if (newProps.stateLoginValue.user_type === 4) {
            this.setState({menuItems: SubProvidermenuItems});
        }
        if (newProps.stateLoginValue.user_type === "") {
            this.setState({menuItems: menuItems});
        }
    }

    handleClick = title => (event) => {
        //console.log(event.currentTarget,'event.currentTarget')
        this.setState({anchor: event.currentTarget, open: title});
    };

    handleClose = () => {
        this.setState({anchor: null, open: null});
    };

    UnreadMsg = (path, title) => {
        if (path === window.location.pathname) {
            window.location.reload();
        }

        let token = this.props.stateLoginValue.token;
        axios.get(sitedata.api + '/message',
            {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response.data.hassuccessed === true) {
                    if (typeof (response.data) !== 'undefined' && typeof (response.data.data) !== 'undefined') {
                        let unreadmsg = response.data.data.filter((value, key) => value.status === 0);
                        if (unreadmsg.length > 0) {
                            this.props.UnreadmsgFetchReducer(unreadmsg.length);
                        } else {
                            this.props.UnreadmsgFetchReducer(false);
                        }
                    }
                }
            })
    }

    clickToUrl = (url) => {
        window.location.href = url;
        return null;
    }
    render() {
        const {classes} = this.props;
        const {anchor, open} = this.state;
        return (

            <Toolbar classes={{root: classes.toolbarClass}} className="webMenu_cstm">
                {this.state.menuItems && this.state.menuItems.length > 0 && this.state.menuItems.map(item => {
                    console.log("Item", item);

                    if (item.href) {
                        if (item.href.search('https:') !== -1) {
                            return (<a
                                className={'MuiButtonBase-root MuiButton-root MuiButton-text '+classes.menuItem}
                                key={item.title}
                                href={item.href}
                                target="_blank"
                            >
                                {item.title}
                            </a>);
                        }

                        return (<Button
                            className={classes.menuItem}
                            key={item.title}
                            exact
                            activeClassName={classes.activeMenuItem}
                            component={NavLink}
                            to={item.href}
                            onClick={() => this.UnreadMsg(item.href, item.title)}
                        >
                            {item.title}
                            {item.title === 'MY MESSAGES' && this.props.unreadmsg &&
                                <span className="notification_bar">{this.props.unreadmsg}</span>}
                            {item.title === 'MY CONNECTIONS' && this.props.unreadConnmsg &&
                                <span className="notification_bar">{this.props.unreadConnmsg}</span>}
                            {item.title === 'MY SPONSORS' && this.props.unreadSpomsg &&
                                <span className="notification_bar">{this.props.unreadSpomsg}</span>}
                            {item.title === 'MY SPONSORED COMMUNITIES' && this.props.unreadSpomsg &&
                                <span className="notification_bar">{this.props.unreadSpomsg}</span>}
                        </Button>);
                    }
                    else if (item.children) {
                        return (<div key={item.title}>
                            <Button
                                aria-owns={anchor && open === item.title ? item.title : null}
                                aria-haspopup="true"
                                onClick={this.handleClick(item.title)}
                                classes={{
                                    root: classes.menuItem
                                }}
                            >
                                {item.title}
                            </Button>
                            <Menu
                                id={item.title}
                                anchorEl={anchor}
                                open={Boolean(open === item.title)}
                                onClose={this.handleClose}
                            >
                                {item.children.map(child => (
                                    <MenuItem
                                        exact
                                        activeClassName={classes.activeMenuItem}
                                        component={NavLink}
                                        to={child.href}
                                        key={child.title}
                                        onClick={this.handleClose}
                                    >
                                        {child.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>);
                    }
                })}
            </Toolbar>
        );
    }
}

WebsiteNavBar.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

const mapStateToProps = (state) => {
    const {stateLoginValue, loadingaIndicatoranswerdetail} = state.LoginReducer;
    const {unreadmsg} = state.UnreadmsgReducer;
    const {unreadConnmsg} = state.UnreadConnReducer;
    const {unreadSpomsg} = state.UnreadSpoReducer;
    return {
        stateLoginValue,
        loadingaIndicatoranswerdetail,
        unreadmsg,
        unreadConnmsg,
        unreadSpomsg
    }
};


// export default withStyles(styles, { withTheme: true })(Index);
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, {LoginFetchReducer, SponsorCountReducer, UnreadmsgFetchReducer, UnreadConnectionFetchReducer})(WebsiteNavBar));
//export default withStyles(styles)(WebsiteNavBar);
