if(process.env.REACT_APP_SERVER_ENVIRONMENT == 'staging'){
  var sitedata = {
    "api"          : process.env.REACT_APP_API_URL+"/websiteApi",
    "url"          : process.env.REACT_APP_API_URL,
    "image_path"   : process.env.REACT_APP_API_URL+"/upld_image/"
  }
}else{
  var sitedata = {
    "api"          : "https://cp.seniorplicity.com/websiteApi",
    "url"          : "https://www.seniorplicity.com",
    "image_path"   : "https://www.seniorplicity.com/upld_image/"
    //"url"       :  "",
    //"image_path" : "/upld_image"
  }
}

exports.sitedata = sitedata;
