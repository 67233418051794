import React from 'react';
import FaceIcon from '@material-ui/icons/Face';
import themes from '../../themes';

export const configuredTheme = themes[0].theme;
export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 16
};

export const VolunteermenuItems = [
  {
    title: 'LOG-IN-HOME',
    href: '/user-details',
    icon: <FaceIcon style={iconStyle} />,
  },
  {
    title: 'MY MESSAGES',
    href: '/messages',
    icon: <FaceIcon style={iconStyle} />
  },
];
