import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';

import MobileNavBar from './MobileNavBar';
import WebsiteNavBar from './WebsiteNavBar';
import Sidenav from './sidenav';
import MenuSidenav from './Navigation';
import ContentFooter from './footer';

import { toggleSidenav, setSidenavOpen, toggleSidenavVariant } from '../client/actions/layout.actions';

import scss from './layout-toolbar.module.scss';
import styles from './layout-toolbar.style';

import axios from 'axios';
import { CommunityFetchReducer } from '../client/actions/community';
import { LoginFetchReducer } from '../client/actions/users.js';
import { sitedata } from '../sitedata'
import { UnreadmsgFetchReducer } from '../client/actions/unreadMessages';
import { UnreadConnectionFetchReducer } from '../client/actions/connections';
import { AddSponsorReducer } from '../client/actions/sponserships';
import { SponsorCountReducer } from '../client/actions/sponsors';
import { SearchFetchReducer } from '../client/actions/search';
import { setShowSearchBtn } from '../client/actions/apiActions';

class ToolbarLayout extends React.Component {
    constructor(props) {
        super(props);
        props.toggleSidenavVariant('temporary');
        props.setSidenavOpen(false);
        this.state= {
            userInfo: [],
            showSearchBtn:false
        }
    }

    componentWillMount(){
        if(this.props.stateLoginValue && this.props.stateLoginValue !== '' && this.props.stateLoginValue.token !== 450){
            axios.get(sitedata.api + '/user/getById',{
                headers:{
                    'authorization': this.props.stateLoginValue.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then((response)=>{
                if (response.data.message === "Session has expired")
                {
                    this.signOut();
                }
                if(response.data.data)
                {
                    this.setState({userInfo: response.data.data});
                }
            })
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps.stateLoginValue !== '' && newProps.stateLoginValue.token !== 450 && typeof(newProps.stateLoginValue.token) !== 'undefined' ){
            axios.get(sitedata.api + '/user/getById',{
                headers:{
                    'authorization': newProps.stateLoginValue.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }}).then((response)=>{
                if(response.data.data){
                    this.setState({userInfo: response.data.data});
                }
            })
        }
        // setting search seniorplicity btn visibility
        if(newProps.data && newProps.data?.pageName == "getClientDashboard" && newProps.data?.listings){
            let showSearchBtn = (Object.keys(newProps.data.listings).length > 1)?false:true;
            this.props.setShowSearchBtn(showSearchBtn);
        }
        if(newProps.location.pathname != '/dashboard'){
            this.props.setShowSearchBtn(true);
        }
    }
    findProvider=()=>{
        this.props.history.push('/find-providers')
    }
    findCommunity=()=>{

        let user_type = parseInt(this.props.stateLoginValue.user_type, 10);
        if(user_type ===2) {
            this.props.history.push({pathname: '/community-search'})
        }else{
            this.props.history.push({pathname: '/community-search'})
        }
    }
    searchVolunteer=()=>{
        this.props.history.push({pathname: '/volunteer-search/search-results'})
    }
    signOut=()=>{
        let email='',password=''
        let data=[]
        let searchData=[]
        this.props.UnreadmsgFetchReducer(false)
        this.props.UnreadConnectionFetchReducer(false)
        this.props.CommunityFetchReducer();
        this.props.AddSponsorReducer(data);
        this.props.SearchFetchReducer(searchData);
        this.props.LoginFetchReducer(email, password);
        this.props.SponsorCountReducer('');
        localStorage.removeItem("searchForm");
        this.props.history.push({pathname: '/'});
    }
    gohome=()=>{
        this.props.history.push({pathname: '/'})
    }
    settings=()=>{
        this.props.history.push({pathname: '/setting'})
    }
    editProfile=()=>{
        this.props.history.push({pathname: '/edit-profile'});
    }

    homeuser=()=>{
        let user_type = parseInt(this.props.stateLoginValue.user_type, 10);
        if(user_type === 0){
            this.props.history.push({pathname: '/dashboard'})
        }

        if(user_type === 1){
            this.props.history.push({pathname: '/dashboard'})
        }

        if(user_type === 2){
            this.props.history.push({pathname: '/user-dashboard'})
        }

        if(user_type === 3){
            this.props.history.push({pathname: '/user-dashboard'})
        }
    }

    searchSeniorplicity = () => {
        this.props.history.push({pathname: '/search'})
    }

    render() {
        const { children, showSearchBtn  } = this.props;

        let user_type = parseInt(this.props.stateLoginValue.user_type, 10);
        
        return (<div className="container-fluid p-0">

            <div className="container-fluid bg-light m-0 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <a href="/" onClick={this.gohome}><img src="/assets/images/logo-2021.png" width="250" alt="SeniorPlicity.com" title="" /></a>
                        </div>
                        <div className="col-12 col-sm-6 text-sm-right nomobile">
                            {this.props.stateLoginValue.token && this.props.stateLoginValue.token !== 450 ? <div className="login_as">
                                You are logged in as {this.state.userInfo.l_name ? this.state.userInfo.f_name+' '+this.state.userInfo.l_name: this.state.userInfo.f_name} {' '} &nbsp; &nbsp;
                                <div className="mobileOnly"><br/></div>
                                <button title="Dashboard" className="btn btn-sm btn-outline-secondary mr-1" onClick={() => this.homeuser()}><i className="far fa-house-user" /></button>
                                <button title="Edit Profile" className="btn btn-sm btn-outline-secondary mr-1" onClick={() => this.editProfile()}><i className="fas fa-user" /></button>
                                <button title="Edit Settings" className="btn btn-sm btn-outline-secondary mr-1" onClick={() => this.settings()}><i className="far fa-cog" /></button>
                                <button title="Sign Out" className="btn btn-sm btn-outline-secondary mr-1" onClick={() => this.signOut()}><i className="far fa-sign-out-alt" /></button>
                            </div> : ''}

                            {(this.props.stateLoginValue.token === null || this.props.stateLoginValue.token === 450) && <Fragment>
                                <a title="Login" href="/login" className="btn btn-sm btn-primary mr-1">LOGIN</a>
                                <a title="Login" href="/create-account" className="btn btn-sm btn-primary mr-1">CREATE ACCOUNT</a>
                            </Fragment>}

                            <div className="setNearBy">
                                { showSearchBtn && <a href="/search" className="btn btn-sm btn-outline-primary mr-1">Search Seniorplicity</a>}
                                {/* user_type !== 2 && user_type !== 3 && <button className="btn btn-sm btn-outline-primary" onClick={() => this.searchVolunteer()}>Find Nearby Volunteers</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Sidenav>
                <MenuSidenav />
            </Sidenav>

            <AppBar color="default" position="static">
                <MobileNavBar />
                <WebsiteNavBar />
            </AppBar>

            <main className={scss['layout-toolbar-main']} style={{paddingBottom: '100px'}}>
            {children}
            </main>

            <ContentFooter />
        </div>);
    }
}

const mapStateToProps = (state) => {
    const { stateLoginValue , loadingaIndicatoranswerdetail } = state.LoginReducer;
    const { unreadmsg } = state.UnreadmsgReducer;
    const { unreadConnmsg } = state.UnreadConnReducer;
    const { stateSearchData } = state.SearchReducer;
    const { selectedcommunities } = state.SponsorReducer;
    return {
        showSearchBtn: state.pages.showSearchBtn,
        data: state.pages.data,
        stateLoginValue,
        loadingaIndicatoranswerdetail,
        unreadmsg,
        unreadConnmsg,
        stateSearchData,
        selectedcommunities
    }
};

ToolbarLayout.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    children: PropTypes.shape({}).isRequired,
    setSidenavOpen: PropTypes.func.isRequired,
    toggleSidenavVariant: PropTypes.func.isRequired,
};

export default withRouter(
    compose(withWidth(), withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, {
        toggleSidenav,
        setSidenavOpen,
        toggleSidenavVariant
    })
)(connect(mapStateToProps,{ LoginFetchReducer,CommunityFetchReducer,SponsorCountReducer,UnreadmsgFetchReducer,AddSponsorReducer, SearchFetchReducer,  UnreadConnectionFetchReducer, setShowSearchBtn })(ToolbarLayout)));




