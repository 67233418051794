import {GET_LIST, GET_DATA, GET_LISTING_AMOUNT, SHOW_SEARCH_BTN} from "../actions/types";

const initialState = {
    data: {},
    dataList: {},
    subscriptionData: {},
    showSearchBtn:false
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DATA:
            return {
                ...state,
                data: action.payload
            };

        case GET_LIST:
            return {
                ...state,
                dataList: action.payload
            };
            
        case GET_LISTING_AMOUNT:
            return {
                ...state,
                subscriptionData: action.payload
            };
        
        case SHOW_SEARCH_BTN:
            return {
                ...state,
                showSearchBtn: action.payload
            };

        default:
            return state;
    }
}