import {GET_UNREAD, GET_UNREAD_FAIL} from '../../actiontypes';

const initial_state = {
    unreadmsg: false
};
const UnreadmsgReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_UNREAD:
            return { ...state, unreadmsg : action.payload }
        case GET_UNREAD_FAIL:
            return { ...state }    
        default:
            return state;
    }
}
export default UnreadmsgReducer;