import {GET_FAMILY_MEMBERS} from '../../actiontypes';

const initial_state = {
    familyMembers:[]
};
const FamilyMemberReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_FAMILY_MEMBERS:
            return { ...state,  familyMembers: action.payload }

        default:
            return state;
    }
}
export default FamilyMemberReducer;