import {GET_ADDED_SPONSORS, GET_SPONSER_LISTING_COUNT} from '../../actiontypes';

const initial_state = {
    selectedcommunities:[],
    sponsorListingCount: {}
};
const SponsorReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_ADDED_SPONSORS:
            return { ...state,  selectedcommunities: action.payload }
        case GET_SPONSER_LISTING_COUNT:
            return {
                ...state,
                sponsorListingCount: action.payload
            };
        default:
            return state;
    }
}
export default SponsorReducer;